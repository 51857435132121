import React from 'react';
import * as style from './layout.module.scss';
import ThemeProvider from './theme-provider';
import { ToastContainer } from 'react-toastify';
import Header from './header/header';
import Footer from './footer/footer';

const Layout: React.FC = ({ children }) => {
  const toastAutoCloseDelay = 5000;
  return (
    <>
      <ThemeProvider>
        <div className={style.container}>
          <ToastContainer
            position="top-center"
            autoClose={toastAutoCloseDelay}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable={false}
            pauseOnHover
          />
          <Header />
          <div className={style.content}>{children}</div>
          <Footer />
        </div>
      </ThemeProvider>
    </>
  );
};

export default Layout;

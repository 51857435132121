import ThemeType from '../common/theme-type';
import React, { useState } from 'react';
import windowGlobal from '../utils/window-global';
import ThemeContext from './theme-context';

export const THEME_STORAGE_KEY = 'color_theme';
const DEFAULT_THEME = ThemeType.LIGHT;

const getDefaultTheme = (): ThemeType => {
  if (!windowGlobal) return DEFAULT_THEME;

  const selectedTheme = windowGlobal.localStorage.getItem(THEME_STORAGE_KEY) as ThemeType;
  return selectedTheme || DEFAULT_THEME;
};

const ThemeProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = useState(getDefaultTheme());

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <main className={theme}>{children}</main>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;

import React from 'react';
import * as style from './footer.module.scss';
import { Link } from 'gatsby';
import LinkedInIcon from '../../images/linkedin.svg';

const Footer: React.FC = () => {
  const iconsSize = 35;
  return (
    <footer className={style.footer}>
      <div className={style.socialLinks}>
        <a
          href="https://www.linkedin.com/in/norbert-bartos"
          target="_blank"
          rel="noreferrer noopener"
          aria-label="LinkedIn profile"
        >
          <LinkedInIcon height={iconsSize} />
        </a>
      </div>
      <div className={style.legalLinks}>
        <Link to="/privacy-and-policy">Privacy policy</Link>
      </div>
    </footer>
  );
};

export default Footer;

import ThemeType from '../common/theme-type';
import React from 'react';

interface IThemeContext {
  theme: ThemeType;
  setTheme?: React.Dispatch<React.SetStateAction<ThemeType>>;
}

const ThemeContext = React.createContext<IThemeContext>({
  theme: ThemeType.LIGHT,
  setTheme: () => null
});

export default ThemeContext;

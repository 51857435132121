import React from 'react';
import { Link } from 'gatsby';
import * as styles from './header.module.scss';

const Header: React.FC = () => (
  <nav className={styles.mainNav}>
    <Link to="/" activeClassName={styles.activeMenuItem}>
      About
    </Link>
    <Link to="/blog/" activeClassName={styles.activeMenuItem}>
      Posts
    </Link>
  </nav>
);

export default Header;
